<template>
  <div v-if="loaded">
    <!-- <div v-html="compiledMarkdown"></div> -->
  </div>
</template>

<script>
import { firestore } from 'firebase'
// import marked from 'marked'

export default {
  data() {
    return {
      loaded: true,
      src: '',
    }
  },
  mounted() {
    firestore().collection('supportTables').doc('MlMZsjSctCRQvjxeKz1N').get()
      .then(doc => {
        this.src = doc.data().src
      })
  },
  // computed: {
  //   compiledMarkdown: function() {
  //     return marked(this.input, { sanitize: true });
  //   }
  // },
}
</script>

<style>
  iframe{
    width: 100%;
    height: calc(100vh - 85px);
  }

  .cui__utils__content{
    padding: 1em;
  }
</style>
